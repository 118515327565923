import { Col, Grid, Row, Typography } from '@hippo/components';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Divider } from 'components/Divider';
import { Arrow, ArrowLink } from 'components/ArrowButton';
import { lennarColorConfig } from 'components/ThemeProviderOverride';
import { CommunityRow } from 'components/CommunityRow';
import { Spinner } from 'components/Spinner';
import { useDebouncedState } from '@mantine/hooks';

import useCommunitiesMetadata from '../../helpers/useCommunitiesMetadata';
import { LennarSearchWrapper } from '../../components/LennarSearchWrapper';
import useCommunities from '../../helpers/useCommunities';
import { removeDuplicates } from '../../utils/removeDuplicates';
import useWhiteLabel from '../../helpers/useWhiteLabel';
import { OrgNameMapping } from '../../helpers/enums/organization';
import { Flex } from '../../components/Flex';
import { getCommunityListPath, getDivisionListPath } from '../../helpers/paths';
import { LennarSearchInput } from '../../components/LennarSearchInput';
import { HeaderContactInfo } from '../../components/HeaderContactInfo/HeaderContactInfo';
import { getSearchParam } from '../../utils/getSearchParam';
import { formatCodeAndPhase } from '../getQuote/utils/formatCodeAndPhase';

export const ITEMS_PER_ROW = 20;

export const CommunityList = () => {
  const history = useHistory();
  const [communityName, setCommunityName] = useDebouncedState(
    getSearchParam(window.location.search, 'communityName') ?? undefined,
    400
  );

  const match = useRouteMatch<{ divisionId: string }>();
  const divisionId = match.params.divisionId || '';
  const metadata = useCommunitiesMetadata();

  const divisionName = metadata.data?.divisions.find((item) => item.value === divisionId)?.display || '';

  const page = parseInt(getSearchParam(window.location.search, 'page') || '0');
  const communityList = useCommunities(
    {
      divisionId: divisionId,
      limit: ITEMS_PER_ROW,
      offset: page * ITEMS_PER_ROW,
      searchName: communityName || undefined,
    },
    { enabled: Boolean(divisionId) }
  );
  const count = communityList.data?.count ?? 0;
  const pagesCount = Math.floor((count - 1) / ITEMS_PER_ROW);
  const state = removeDuplicates(communityList.data?.rows.map(({ state }) => state) ?? []);

  const whiteLabel = useWhiteLabel({
    org: OrgNameMapping.Lennar,
    state: state,
  });

  const noResults = communityList.isLoading === false && count === 0;

  return (
    <LennarSearchWrapper
      pages={whiteLabel.data?.pages}
      builder={whiteLabel.data?.builder}
      licenses={state.length > 0 ? whiteLabel.data?.licenses ?? [] : []}
      contactInfo={
        whiteLabel.data?.builder ? <HeaderContactInfo phone={whiteLabel.data?.pages.thank_you.phone} /> : undefined
      }
    >
      <Grid style={{ width: '100%' }} flex={1}>
        <Row>
          <Col offset={[0, 2 / 12, 2 / 12]} size={[1, 8 / 12, 8 / 12]}>
            <Typography type='allCaps2' color='primary500'>
              <Link to={getDivisionListPath()} style={{ textDecoration: 'none' }}>
                All divisions
              </Link>{' '}
              / {divisionName} communities
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col mt='20px'>
            <Typography type='heading4' color='black' textAlign='center'>
              Select your community
            </Typography>
          </Col>
        </Row>
        <Row>
          {divisionName && (
            <Col flexDirection='row' justifyContent='center' mt='3'>
              <Typography type='body4' color='gray600' textAlign='center' as='span'>
                Here are communities in the the division
                <Typography type='body4' color='primary500' textAlign='center' as='span'>
                  &nbsp;&quot;{divisionName}&quot;
                </Typography>
                . Choose the community or search.
              </Typography>
            </Col>
          )}
        </Row>

        <Flex mt='26px' />
        <LennarSearchInput
          label='Community (Name or Phase or Code)'
          name='community'
          value={communityName ?? ''}
          onChange={(value) => {
            setCommunityName(value);
            history.replace(getCommunityListPath(divisionId));
          }}
          showResetButton={noResults}
          onResetButtonClick={() => {
            setCommunityName('');
            history.push(getCommunityListPath(divisionId));
          }}
        />

        {noResults && (
          <Flex width='100%' justifyContent='center' mt='50px'>
            <Typography type='body4' color='gray600'>
              No results for the “{communityName}”
            </Typography>
          </Flex>
        )}

        {communityList.isLoading ? (
          <Row center={true} pt='56px' pb='12px'>
            <Spinner />
          </Row>
        ) : (
          <>
            <Row>
              <Col size={[1, 8 / 12, 8 / 12]} offset={[0, 2 / 12, 2 / 12]}>
                <>
                  {communityList.data?.rows.map((community) => (
                    <Link
                      to={`/communities/${community.id}/ratesheet`}
                      key={community.id}
                      style={{ textDecoration: 'none' }}
                    >
                      <CommunityRow py='12px'>
                        <Col size={4.5 / 12}>
                          <Typography type='body4' color='primary500' textAlign='left'>
                            {community.community_name}
                          </Typography>
                        </Col>
                        <Col size={7.5 / 12}>
                          <Typography type='body5' color='gray600' textAlign='left'>
                            {community.street ? `${community.street},` : null}{' '}
                            {community.city ? `${community.city},` : null} {community.state} {community.zip}{' '}
                            {formatCodeAndPhase(community.code, community.phase)}
                          </Typography>
                        </Col>

                        <Arrow fill={lennarColorConfig.primary500} />
                      </CommunityRow>
                      <Divider />
                    </Link>
                  ))}
                </>
              </Col>
            </Row>

            {!noResults && (
              <Row pt='10px'>
                <Col size={10 / 12} flexDirection='row' justifyContent='flex-end' alignItems='center'>
                  <Typography type='body6' color='gray800' textAlign='center'>
                    Page {page + 1} of {pagesCount + 1}
                  </Typography>

                  <ArrowLink
                    direction='left'
                    disabled={page === 0}
                    to={`${getCommunityListPath(divisionId)}?page=${page - 1}`}
                    data-testid='previous-page'
                  />
                  <ArrowLink
                    disabled={pagesCount === page}
                    to={`${getCommunityListPath(divisionId)}?page=${page + 1}`}
                    data-testid='next-page'
                  />
                </Col>
              </Row>
            )}
          </>
        )}
      </Grid>
    </LennarSearchWrapper>
  );
};

/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react/display-name */
import React, { ReactElement, ReactNode } from 'react';
import { Col, Divider, Grid, Row, Typography } from '@hippo/components';

import { BannerImg, StyledFontWeight, StyledGrid } from './style';
import { background, BackgroundColorProps } from 'styled-system';
import styled from 'styled-components';
import { OrgNameMapping } from '../../helpers/enums/organization';
import cardsData from './cards/cardsData';
import Box from '../../components/box/Box';
import { states } from '../../helpers/enums/states';
import { WhiteLabelResponse } from '../../types/whiteLabel';
import { Flex } from '../../components/Flex';
import { HBIA } from '../../components/icons/HBIA';
import { License } from '../../reducers/white-label/types';
import { Footer } from '../../components/footer/Footer';
import { ConsentData } from '../../reducers/consent/types';

const RowWithBackgroundColor = styled(Row)<BackgroundColorProps>`
  ${background}
`;

const MattamyHeaderImage = styled.img({
  borderRadius: '8px',
  width: '398px',
  height: '398px',
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: 'cover',
  aspectRatio: '1',
});

const IconContainer = styled.div(({ theme }: { theme: HippoComponents.ThemeConfig }): any => ({
  padding: '15px',
  width: '100%',
  maxWidth: '140px',
  height: '100%',
  color: theme.colors.primary300,
}));

type LandingContent = {
  orgName: string;
  banner: React.ReactNode;
  body: ({ whiteLabel }: { whiteLabel: WhiteLabelResponse }) => React.ReactNode;
  header: {
    space: string;
    text: React.ReactNode;
  };
  licenseInformation: (licenses: License[]) => React.ReactNode;
  footer: (params: {
    whiteLabel: WhiteLabelResponse;
    footerLinks: WhiteLabelResponse['pages']['footer'];
    consentWhiteLabel: ConsentData['white_label'];
  }) => ReactNode;
};

const emptyContent: LandingContent = {
  orgName: '',
  banner: null,
  body: () => null,
  header: {
    space: '0px',
    text: '',
  },
  licenseInformation: () => null,
  footer: () => null,
};

const getDefaultContentText = (orgShortName: string) => (
  <>
    Now that you&apos;ve purchased your {orgShortName} home, we can get you the home insurance policy you need.{' '}
    {orgShortName} Insurance Agency makes it simple: We know your home inside and out - after all, {orgShortName} built
    it - so we provide a tailored policy, customized specifically for your home. Plus, since our policy is designed for
    new home construction, we can secure a competitive price from one of our top-rated insurers, helping get you one
    step closer to holding the keys to your new home.
  </>
);

const getLicenseList = (licenses: License[]) => {
  return (
    <>
      {licenses?.map((license, index) => (
        <Typography type={'body5'} color={'gray600'} as={'span'} key={index}>
          <div style={{ whiteSpace: 'pre-wrap' }}>
            {license.state} {license.license_number}
            {index < licenses.length - 1 ? ', ' : ''}
          </div>
        </Typography>
      ))}
    </>
  );
};

const getEmptyFooter = (footerLinks: WhiteLabelResponse['pages']['footer']) => (
  <Box backgroundColor={'gray50'}>
    <Footer licenses={[]} builder={undefined} links={footerLinks} />
  </Box>
);

export const getBuilderContent = ({
  orgName,
  orgShortName,
  overrideOrgShortName,
  bannerImg,
  cardsContent = [],
  showBanner,
  overrideWhiteLabel,
  showWhiteLabel,
  text,
  licenseInformation,
  footer,
}: {
  orgName: string;
  orgShortName: string;
  overrideOrgShortName?: string;
  bannerImg: ReactElement | null;
  cardsContent?: string[];
  overrideWhiteLabel?: WhiteLabelResponse['licenses'];
  showWhiteLabel: boolean;
  showBanner: boolean;
  text: ReactNode;
  licenseInformation?: (licenses: License[]) => ReactNode;
  footer?: (params: {
    whiteLabel: WhiteLabelResponse;
    footerLinks: WhiteLabelResponse['pages']['footer'];
  }) => ReactNode;
}): LandingContent => ({
  orgName,
  banner: showBanner ? (
    <>
      <RowWithBackgroundColor paddingTop={['50px', '50px', '100px']} backgroundColor={'white'}>
        <Col size={[1, 1, 6 / 12]}>
          <Typography type={['heading3', 'heading3', 'heading3']} color={'primary500'}>
            <StyledFontWeight>The simplest way to insure your {orgShortName} home</StyledFontWeight>
          </Typography>
          <Typography type={'body3'} color={'gray600'} paddingTop={'40px'} as={'p'}>
            {text}
          </Typography>
        </Col>
        <Col size={[1, 1, 6 / 12]} alignItems={['center', 'flex-end', 'flex-end']}>
          {bannerImg}
        </Col>
      </RowWithBackgroundColor>
      <Row paddingTop={'120px'} paddingBottom={'100px'}>
        <Divider color='gray100' />
      </Row>
    </>
  ) : null,
  header: {
    space: showBanner ? '0px' : '150px',
    text: `Three great benefits to get you one step closer to home sweet home.`,
  },
  body: ({ whiteLabel }: { whiteLabel: WhiteLabelResponse }) => (
    <>
      {[
        {
          ...cardsData.saveMoney,
          content:
            cardsContent[0] ??
            `${
              overrideOrgShortName || orgShortName
            } Insurance Agency, LLC offers policies specifically designed for new home construction, something hard to find with other insurers. This could save you on your property insurance premium.`,
        },
        {
          ...cardsData.home,
          content:
            cardsContent[1] ??
            'We built the home and have all the details needed to create a customized property insurance policy with just the right coverage for your new home.',
        },
        {
          ...cardsData.watch,
          content:
            cardsContent[2] ??
            'Home insurance applications can be lengthy. We pre-fill the answers so there are no additional steps or credit checks required, and prepare a policy for you that is ready to be reviewed and signed in minutes, not days.',
        },
      ].map((card, index) => (
        <Col size={1} paddingTop='110px' key={index}>
          <Grid>
            <Row>
              <Col size={[1, 1, 2 / 12]} offset={[0, 0, 1 / 12]} alignItems='center'>
                <IconContainer>{card.icon}</IconContainer>
              </Col>
              <Col size={[1, 1, 2.5 / 12]}>
                <Typography type='heading7' color='primary300'>
                  {card.title}
                </Typography>
              </Col>
              <Col size={[1, 1, 5 / 12]} offset={[0, 0, 0.5 / 12]}>
                <Typography type='body3' color='gray600' as='p'>
                  {card.content}
                </Typography>
              </Col>
            </Row>
          </Grid>
        </Col>
      ))}

      {showWhiteLabel ? (
        <Box backgroundColor={'gray50'} marginTop={'150px'}>
          <StyledGrid>
            <Row>
              <Col size={1}>
                <Typography type='body1' color='gray700' paddingTop='80px' paddingBottom='40px' as='h2'>
                  {orgShortName} Insurance Agency, LLC makes its home in Irving, <br /> Texas and serves customers
                  across the country.
                </Typography>
              </Col>
            </Row>
            <Row paddingBottom='92px'>
              {(overrideWhiteLabel ?? whiteLabel.licenses).map(({ state }) => (
                <Col size={[6 / 12, 4 / 12, 3 / 12]} key={state}>
                  <Typography type={'body3'} color={'gray600'} paddingTop={'15px'} as={'p'}>
                    {states[state.toLocaleLowerCase().toString()]}
                  </Typography>
                </Col>
              ))}
            </Row>
          </StyledGrid>
        </Box>
      ) : null}
    </>
  ),
  licenseInformation: licenseInformation
    ? licenseInformation
    : (licenses: License[]) => (
        <StyledGrid>
          <Row center={true} paddingTop={'150px'}>
            <Typography textAlign='center' type={'body5'} color={'gray600'} as={'p'}>
              {`You are not required to use ${orgName} Agency to secure a policy to provide home
                      insurance coverage.`}
            </Typography>
          </Row>
          <Row paddingTop={'10px'} center={true}>
            <Typography type={'body5'} color={'gray600'} as={'span'}>
              {`License#: `}
            </Typography>
            <>
              {licenses?.map((license, index) => (
                <Typography type={'body5'} color={'gray600'} as={'span'} key={index}>
                  {`${license.state} #${license.license_number},`}
                </Typography>
              ))}
            </>
          </Row>
        </StyledGrid>
      ),
  footer: footer
    ? footer
    : ({ whiteLabel, footerLinks, consentWhiteLabel }) => (
        <Box backgroundColor={'gray50'} marginTop={'30px'}>
          <Footer
            licenses={
              whiteLabel
                ? [
                    {
                      state: consentWhiteLabel.state,
                      license_number: consentWhiteLabel.license_number,
                      description: '',
                    },
                  ]
                : []
            }
            builder={whiteLabel.builder}
            links={footerLinks}
          />
        </Box>
      ),
});

export const consentFlowContent = {
  title: 'Smart coverage for',
  tollTitle: 'Congratulations on buying your new Toll-Brothers house!',
};

export const referralFlowContent = {
  title: 'Tailored Coverage for Your Lennar Home',
  tollTitle: 'Congratulations on buying your new Toll-Brothers house!',
};

export const bindFlowContent = {
  buttonText: 'Buy Online Now',
  errorMessage: (
    <Typography type={'body4'} as={'p'} color={'error'}>
      There is an error, please try again.
    </Typography>
  ),
};

const lennarContent = getBuilderContent({
  orgName: 'Lennar Insurance',
  orgShortName: 'Lennar',
  bannerImg: <BannerImg src='/images/lennar-header.jpg' alt={'congrats image'} />,
  showWhiteLabel: false,
  text: getDefaultContentText('Lennar'),
  showBanner: true,
});

const tollContent = getBuilderContent({
  orgName: 'Hippo Builder Insurance',
  orgShortName: 'Hippo Builder',
  bannerImg: null,
  showWhiteLabel: false,
  text: getDefaultContentText('Toll Brothers'),
  showBanner: false,
});

const khovContent = getBuilderContent({
  orgName: 'Hovnanian Insurance Agency, LLC',
  orgShortName: 'Hippo Builders',
  bannerImg: (
    <Flex flexDirection='column'>
      <Flex justifyContent={['center', 'unset', 'unset']}>
        <BannerImg src='/images/khov-header.jpg' alt={'congrats image'} />
      </Flex>

      <Flex padding='0 40px' marginTop='60px'>
        <HBIA />
      </Flex>
    </Flex>
  ),
  text: (
    <>
      When you purchase a K. Hovnanian home, we can get you the home insurance policy you need. Hovnanian Insurance
      Agency, LLC makes it simple: We know your home inside and out - after all, we built it - so we provide a tailored
      policy, customized specifically for your home.
      <br />
      Plus, since our policy is designed for new home construction, we can secure a competitive price from one of our
      select group of insurers, helping get you one step closer to holding the keys to your new home. Hippo Builder
      Insurance Agency is now operating Hovnanian Insurance Agency.
    </>
  ),
  cardsContent: [cardsData.saveMoney.content, cardsData.home.content, cardsData.watch.content],
  overrideWhiteLabel: [
    'AL',
    'IA',
    'NE',
    'RI',
    'AZ',
    'KS',
    'NV',
    'SC',
    'AR',
    'KY',
    'NH',
    'SD',
    'CA',
    'LA',
    'NJ',
    'TN',
    'CO',
    'ME',
    'NM',
    'TX',
    'CT',
    'MD',
    'NY',
    'UT',
    'DE',
    'MA',
    'NC',
    'VT',
    'DC',
    'MI',
    'ND',
    'VA',
    'FL',
    'MN',
    'OH',
    'WA',
    'GA',
    'MS',
    'OK',
    'WV',
    'ID',
    'MO',
    'OR',
    'WI',
    'IL',
    'MT',
    'PA',
    'WY',
    'IN',
  ].map((state) => ({
    state,
    license_number: '',
    description: '',
  })),
  showWhiteLabel: true,
  showBanner: true,
  licenseInformation: (licenses: License[]) => (
    <StyledGrid>
      <Row paddingTop={'50px'}>
        <Typography type={'body5'} color={'gray600'} as={'p'}>
          Hippo Builder Insurance Agency is now operating Hovnanian Insurance Agency. You are not required to use Hippo
          Builders Insurance Agency, LLC (HBIA), an affiliate company, to secure your homeowner insurance coverage. Any
          quote for homeowner&apos;s and/or flood insurance is from HBIA. If any enhancements to a standard policy such
          as increased limits, scheduled articles, and/or earthquake coverage are required, the premium may increase.
          Actual quote and acceptance by HBIA is subject to HBIA&apos;s underwriting guidelines, including but not
          limited to verification of your credit score and previous loss history. The cost of your insurance may vary
          due to many factors including, without limitation, the size, location and cost of your home.
        </Typography>
      </Row>
      <Row paddingBottom={'50px'} paddingTop='20px'>
        <Typography type={'body5'} color={'gray600'} as={'span'}>
          Hippo Builders Insurance Agency, LLC License #:&nbsp;
        </Typography>
        {getLicenseList(licenses)}
      </Row>
    </StyledGrid>
  ),
  footer: ({ footerLinks }) => getEmptyFooter(footerLinks),
});

const mattamyContent = getBuilderContent({
  orgName: 'Mattamy',
  orgShortName: 'Mattamy',
  overrideOrgShortName: 'Hippo Builders',
  bannerImg: (
    <Flex flexDirection='column'>
      <Flex justifyContent={['center', 'unset', 'unset']}>
        <BannerImg src='/images/mattamy-header.jpg' alt={'congrats image'} />
      </Flex>

      <Flex padding='0 40px' marginTop='60px'>
        <HBIA />
      </Flex>
    </Flex>
  ),
  showWhiteLabel: true,
  text: (
    <>
      When you purchase a Mattamy home, we can get you the home insurance policy you need. Mattamy Insurance Agency
      makes it simple: We know your home inside and out - after all, we built it - so we provide a tailored policy,
      customized specifically for your home.
      <br />
      Plus, since our policy is designed for new home construction, we can secure a competitive price from one of our
      select group of insurers, helping get you one step closer to holding the keys to your new home.
      <br />
      Hippo Builder Insurance Agency is now operating Mattamy Insurance Agency.
    </>
  ),
  showBanner: true,
  licenseInformation: (licenses: License[]) => (
    <StyledGrid>
      <Row paddingTop={'50px'}>
        <Typography type={'body5'} color={'gray600'} as={'p'}>
          Hippo Builder Insurance Agency is now operating Mattamy Insurance Agency. You are not required to use Hippo
          Builders Insurance Agency, LLC (HBIA), an affiliate company, to secure your homeowner insurance coverage. Any
          quote for homeowner&apos;s and/or flood insurance is from HBIA. If any enhancements to a standard policy such
          as increased limits, scheduled articles, and/or earthquake coverage are required, the premium may increase.
          Actual quote and acceptance by HBIA is subject to HBIA&apos;s underwriting guidelines, including but not
          limited to verification of your credit score and previous loss history. The cost of your insurance may vary
          due to many factors including, without limitation, the size, location and cost of your home.
        </Typography>
      </Row>
      <Row paddingBottom={'50px'} paddingTop='20px'>
        <Typography type={'body5'} color={'gray600'} as={'span'}>
          Hippo Builders Insurance Agency, LLC License #:&nbsp;
        </Typography>
        {getLicenseList(licenses)}
      </Row>
    </StyledGrid>
  ),
  footer: ({ footerLinks }) => getEmptyFooter(footerLinks),
});

const hbiaContent = getBuilderContent({
  orgName: 'Hippo Builder Insurance',
  orgShortName: 'Hippo Builder',
  bannerImg: <BannerImg src='/images/hbia-header.jpeg' alt={'congrats image'} />,
  showWhiteLabel: false,
  text: getDefaultContentText('Hippo Builder'),
  showBanner: true,
});

export const getLandingContent = (orgName?: OrgNameMapping | ''): LandingContent => {
  switch (orgName) {
    case OrgNameMapping.Lennar:
      return lennarContent;

    case OrgNameMapping.Toll:
      return tollContent;

    case OrgNameMapping.Khov:
      return khovContent;

    case OrgNameMapping.Mattamy:
      return mattamyContent;

    case OrgNameMapping.Hbia:
      return hbiaContent;

    default:
      return emptyContent;
  }
};

import { coveragesId } from '../coveragesId';
import React from 'react';
import { PageType } from '../../../helpers/enums/pageType';
import { push } from 'connected-react-router';
import { PAGES } from '../../../globals/routes';
import { saveSmartHomeKit } from '../../../actions/consent';
import { event } from 'react-fullstory';
import { openModal } from '../../../actions/modals';
import { ModalNames } from '../../../types/modals';
import { useDispatch } from 'react-redux';
import { AppThunkDispatch } from '../../../types';
import { useLocation } from 'react-router';
import { useFlagship } from '@flagship.io/react-sdk';
import { landingPageCTAClickHit } from '../../../helpers/useFlagshipFlag';
import { useRedirectToBBXConsentFlow } from '../../../experiments/hooks/useRedirectToBBXConsentFlow';
import { AsyncAwareButton } from '../../../components/AsyncAwareButton';

type GetMyPolicyButtonProps = {
  isCarrierHippo: boolean;
  quoteId?: string | null;
  search?: string | null;
  smartHomeKit: boolean;
  buttonClick?: () => void;
  children: React.ReactNode;
  pageType: PageType;
  buttonSize: HippoComponents.ButtonProps['size'];
  width?: string;
};

export const GetMyPolicyButton = ({
  isCarrierHippo,
  quoteId,
  smartHomeKit,
  buttonClick,
  pageType,
  children,
  buttonSize,
  width,
}: GetMyPolicyButtonProps) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { search } = useLocation();
  const flagship = useFlagship();
  const { consentFlowUrl, isRedirectToConsentFlow } = useRedirectToBBXConsentFlow();
  const [showLoader, setShowLoader ] = React.useState(false);

  const goToNextPage = async () => {
    if (pageType === PageType.Confirm) {
      dispatch(push(`/confirm/${quoteId}/${PAGES.checkout}${search}`));
    } else if (pageType === PageType.Referral) {
      buttonClick && buttonClick();
    } else {
      if (isRedirectToConsentFlow && consentFlowUrl) {
        setShowLoader(true);
        window.location.href = consentFlowUrl;

        // Chrome browser - reset button state in case of user pressing back button
        setTimeout(() => {
          setShowLoader(false);
        }, 5000);
      } else {
        dispatch(push(`/consent/${quoteId}/${PAGES.eligibility}${search}`));
      }
    }
  };

  const saveSmartHomeKitOption = async (val: boolean) => {
    if (isCarrierHippo) {
      if (!quoteId) {
        throw new Error('No quote id provided');
      }
      await dispatch(saveSmartHomeKit({ quoteId, smart_home_kit: val }));
    }
  };

  const handlePrimaryModalClick = async () => {
    saveSmartHomeKitOption(true);
    goToNextPage();
  };

  const handleSecondaryModalClick = async () => {
    saveSmartHomeKitOption(false);
    goToNextPage();
  };

  const handleClickButton = async () => {
    event('Get My Policy');
    flagship.hit.send(landingPageCTAClickHit);

    // If the carrier is hippo we ask if the user wants Notion smart home discount and we must
    // save their response, if the carrier isn't hippo then we can proceed directly to the next page
    if (isCarrierHippo) {
      if (pageType !== PageType.Confirm && pageType !== PageType.Static) {
        if (smartHomeKit) {
          if (!quoteId) {
            throw new Error('No quote id provided');
          }
          dispatch(
            openModal({
              modalName: ModalNames.SmartHomeConsentModal,
              onPrimaryButtonClick: handlePrimaryModalClick,
              onSecondaryButtonClick: handleSecondaryModalClick,
            })
          );
        } else {
          saveSmartHomeKitOption(false);
          goToNextPage();
        }
      } else {
        saveSmartHomeKitOption(smartHomeKit);
        goToNextPage();
      }
    } else {
      goToNextPage();
    }
  };

  return (
    <AsyncAwareButton
      onClick={handleClickButton}
      styleType='primary'
      size={buttonSize}
      data-testid={coveragesId.getMyPolicyButton}
      width={width}
      showLoader={showLoader}
    >
      {children}
    </AsyncAwareButton>
  );
};

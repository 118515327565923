import { useRouteMatch } from 'react-router-dom';
import { useIsNewConsentFlowExperiment } from './useIsNewConsentFlowExperiment';

export const getNewConsentFlowUrl = (consentId: string, newConsentFlowUrl: string) => {
  const currentUrlSearchParams = new URLSearchParams(window.location.search);
  const destinationConsentFlowUrl = new URL(`consent/${consentId}/eligibility`, newConsentFlowUrl);

  currentUrlSearchParams.forEach((value, key) => {
    destinationConsentFlowUrl.searchParams.set(key, value);
  });

  return destinationConsentFlowUrl.toString();
};

/**
 * This experiment should be exposed only when the user is on the consent flow page
 */
export const useRedirectToBBXConsentFlow = () => {
  const matchConsentRoute = useRouteMatch<{ quoteId: string }>(['/consent/:quoteId/*']);
  const shouldExposeExperiment = matchConsentRoute?.isExact;

  const isRedirectToConsentFlowFlagValue = useIsNewConsentFlowExperiment(Boolean(shouldExposeExperiment)) as
    | undefined
    | boolean;

  const newConsentFlowUrl = window.appConfig?.BBX_CONSENT_FLOW_URL;

  if (isRedirectToConsentFlowFlagValue && shouldExposeExperiment && newConsentFlowUrl) {
    const { quoteId: consentId } = matchConsentRoute?.params;

    return {
      isRedirectToConsentFlow: true,
      consentFlowUrl: getNewConsentFlowUrl(consentId, newConsentFlowUrl),
    };
  }

  return {
    isRedirectToConsentFlow: false,
    consentFlowUrl: null,
  };
};

import { ConsentData } from '../reducers/consent/types';
import useQuoteId from './use-quote-id';
import { useEffect, useState } from 'react';
import { useFlagship } from '@flagship.io/react-sdk';

export const createVisitorContext = (consentData: ConsentData, quoteId: string | undefined): FlagshipContext => {
  const initialQuoteDate = consentData.quote?.initial_quote_date
    ? new Date(consentData.quote.initial_quote_date)
    : undefined;
  const consentOrgId = consentData.organization.id;

  return {
    ...(initialQuoteDate && { hbo_initial_quote_date: initialQuoteDate.getTime() }),
    ...(quoteId && { consent_id: quoteId }),
    hbo_is_lennar_lead: consentOrgId === 1,
  };
};

type FlagshipContext = {
  consent_id?: string;
  hbo_is_lennar_lead?: boolean;
  hbo_initial_quote_date?: number;
};

export const hasContextChanged = (prevContext: FlagshipContext, currentContext: FlagshipContext) => {
  const {
    consent_id: prevConsentId,
    hbo_is_lennar_lead: prevHboIsLennarLead,
    hbo_initial_quote_date: prevHboInitialQuoteDate,
  } = prevContext;

  const {
    consent_id: currentConsentId,
    hbo_is_lennar_lead: currentHboIsLennarLead,
    hbo_initial_quote_date: currentHboInitialQuoteDate,
  } = currentContext;

  return [
    prevConsentId !== currentConsentId,
    prevHboIsLennarLead !== currentHboIsLennarLead,
    prevHboInitialQuoteDate !== currentHboInitialQuoteDate,
  ].some(Boolean);
};

export const FETCH_FLAG_STATUS = {
  IDLE: 'IDLE',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  LOADING: 'LOADING',
} as const;

export const useFetchFlagshipFlagsBasedOnVisitorContext = ({ consentData }: { consentData: ConsentData }) => {
  const quoteId = useQuoteId();
  const [fetchFlagStatus, setFetchFlagStatus] = useState<keyof typeof FETCH_FLAG_STATUS>(FETCH_FLAG_STATUS.IDLE);
  const currentContext = createVisitorContext(consentData, quoteId);

  const {
    fetchFlags,
    updateContext,
    context: previousContext,
    status: { isLoading, isVisitorDefined },
  } = useFlagship();

  useEffect(() => {
    if (!isVisitorDefined) {
      setFetchFlagStatus(FETCH_FLAG_STATUS.ERROR);
      return;
    }

    if (isLoading || !previousContext) {
      return;
    }

    if (hasContextChanged(previousContext as FlagshipContext, currentContext)) {
      setFetchFlagStatus(FETCH_FLAG_STATUS.LOADING);
      updateContext(currentContext);
      window.heap.addUserProperties(currentContext);

      void fetchFlags()
        .then(() => {
          setFetchFlagStatus(FETCH_FLAG_STATUS.SUCCESS);
        })
        .catch(() => {
          setFetchFlagStatus(FETCH_FLAG_STATUS.ERROR);
        });
    }
  }, [consentData, currentContext, fetchFlags, isLoading, isVisitorDefined, previousContext, quoteId, updateContext]);

  return fetchFlagStatus;
};

import { FlagshipProvider, LogLevel } from '@flagship.io/react-sdk';
import { PropsWithChildren } from 'react';
import { useModelId } from '../../helpers/use-route-query';
import { useRouteMatch } from 'react-router-dom';
import { LoadingFallback } from '../LoadingFallback';

export const getIsFlagshipEnabled = () => {
  const envId = window?.appConfig?.FLAGSHIP_ENV_ID;
  const apiKey = window?.appConfig?.FLAGSHIP_API_KEY;

  return envId && apiKey;
};

export const FlagshipWrapper = ({ children }: PropsWithChildren<unknown>) => {
  const modelId = useModelId();

  const routeMatch = useRouteMatch<{ quoteId: string }>([
    '/consent/:quoteId/*',
    '/static/:quoteId/*',
    '/confirm/:quoteId/*',
  ]);

  const envId = window?.appConfig?.FLAGSHIP_ENV_ID ?? '';
  const apiKey = window?.appConfig?.FLAGSHIP_API_KEY ?? '';

  const visitorId = routeMatch?.params?.quoteId || modelId || undefined;

  if (!envId || !apiKey || !visitorId) return <>{children}</>;

  return (
    <FlagshipProvider
      envId={envId}
      apiKey={apiKey}
      visitorData={{
        isAuthenticated: false,
        id: visitorId,
      }}
      logLevel={LogLevel.ERROR}
      loadingComponent={<LoadingFallback />}
      // We need to add context later and re-fetch flags when context changes
      fetchNow={false}
      data-testid='flagship-provider'
    >
      {children}
    </FlagshipProvider>
  );
};
